import notification from 'core/libs/helpers/notification';
import {navigate} from 'gatsby';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {
  compose,
  lifecycle,
  setPropTypes,
  withHandlers,
  withState,
} from 'recompose';
import {sendVerificationMail} from '../actions/checkMail';

const {PARTNER} = process.env;

const VerifyEmail = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {email, customEmail, onChange} = props;
  return (
    <Container className="mt5-ns bg-white pv4 pt4-ns pb4-ns ph4">
      <Head
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_email_confirmation'
              : 'partner_helmet_reset_password',
        })}
      />
      <div className="tr-ns tc">
        <h3 className="f3 fw6 mb3">
          <FormattedMessage id="send_verification_title" />
        </h3>
        <div className="w-70-ns w-40-l">
          <p>
            <FormattedMessage id="details_verification_email" />
          </p>
          <div className="pv2 mb3">
            <input
              aria-label="Label email adress"
              className="input-reset mt3 pv3 pr3 pl2 dark-gray ba b--light-gray br2 w-100 tr"
              placeholder={formatMessage({
                id: 'label_email_adress',
              })}
              disabled={email}
              name="email"
              onChange={onChange}
              type="email"
              value={email || customEmail}
            />
          </div>
        </div>
        <button
          aria-label="Send verification button"
          type="submit"
          className="w-auto-ns w-100 pv3 ph4 f6 bg-blue bg-animate hover-bg-dark-blue lh-solid bn br2 white b pointer"
          onClick={sendVerificationMail}
        >
          <FormattedMessage id="send_verification_button" />
        </button>
      </div>
    </Container>
  );
};

VerifyEmail.propTypes = {
  email: PropTypes.string,
  customEmail: PropTypes.string,
  onChange: PropTypes.func,
};

const enhance = compose(
  connect(({user: {profile}, auth}) => ({
    authenticated: auth.authenticated,
    email: profile.data.email,
    is_verified: profile.data.is_verified,
  })),
  setPropTypes({
    authenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func,
    email: PropTypes.string,
    is_verified: PropTypes.bool,
  }),
  withState('customEmail', 'setCustomEmail', ''),
  withHandlers({
    redirectIfVerified: ({authenticated, is_verified}) => () => {
      if (authenticated && is_verified) {
        navigate('/tracks');
        notification({
          id: 'email_already_verified',
          type: 'success',
        });
      }
    },
    sendVerificationMail: ({dispatch, email, customEmail}) => () => {
      dispatch(sendVerificationMail(email || customEmail));
    },
    onChange: ({setCustomEmail}) => (e) => {
      setCustomEmail(e.target.value);
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.redirectIfVerified();
    },
    componentDidUpdate() {
      this.props.redirectIfVerified();
    },
  }),
);

export default enhance(VerifyEmail);
